import {ComboBox} from "../../comboBox/ComboBox.jsx";
import '../../../scss/PowerTabs.scss';
import * as React from "react";
import {useEffect, useState} from "react";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {EmailTextBox} from "../../emailTextBox/EmailTextBox.jsx";

import {Backdrop, Button, CircularProgress, TextField} from "@mui/material";
import {setForm} from "./SetForm.jsx";
import {makeApolloClientCall} from "../../../api/makeApolloApiCall.js";
import {
    DEPARTMENTS_NEW_QUERY, MDM_BRANDS_QUERY, MDM_COMPANIES_QUERY,
} from "./queries.js";
import {UPSERT_MUTATION} from "./mutations.js";
import {validate} from "./subscription/ValidateSubscription.jsx";
import ConfirmationDialog from "../common/dialog/ConfirmationDialog.jsx";
import {ALL_OPTIONS, DEFAULT_CATEGORIES, DEFAULT_DEPARTMENT, DEFAULT_SUBCATEGORIES} from "./constants.js";


export const SubscriptionForm = ({initialState}) => {
    const [errors, setErrors] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isFetchingBrands, setIsFetchingBrands] = useState(false)
    const [isEdit] = useState(Boolean(initialState.id))
    const [isToEmailClear, setIsToEmailClear] = useState(false)
    const [isCcEmailClear, setIsCcEmailClear] = useState(false)
    const [isBccEmailClear, setIsBccEmailClear] = useState(false)
    const [companyList, setCompanyList] = useState([])
    const [brandList, setBrandList] = useState([])
    const [departmentList, setDepartmentList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [subcategoryList, setSubcategoryList] = useState([])

    const dataGroup = initialState.dataGroup
    const dataGroupId = initialState.dataGroupId
    const productUniverses = initialState.productUniverses
    const aggregationPeriods = initialState.aggregationPeriods

    const [id, setId] = useState(initialState.id)
    const [data, setData] = useState({
        form: setForm(initialState)
    })
    const [openOverwriteDialog, setOpenOverwriteDialog] = useState(false)
    const [openDuplicateDialog, setOpenDuplicateDialog] = useState(false)

    const handleDialogClose = (value, setter) => {
        setter(value)
    }

    const handleConfirm = (value, dialogCloseSetter) => {
        dialogCloseSetter(false)
        if (value) {
            const formErrors = validateForm()
            setIsSubmitting(!formErrors)
        }
    }


    const clearErrorFor = (name) => {
        setErrors({
                ...errors,
                [name]: undefined
            }
        )
    }

    const onChange = (value, stateKey) => {
        const form = data.form
        form[stateKey] = value
        clearErrorFor(stateKey)
        setData({
            ...data,
            form
        })
    };

    const validateForm = () => {
        const errors = validate(data.form)
        setErrors({})
        setErrors(errors)
        return Object.keys(errors).length > 0
    }

    const handleSubmit = (event, dialogStateSetter) => {
        event.preventDefault()
        if (isEdit) {
            dialogStateSetter(true)
        } else {
            const formErrors = validateForm()
            setIsSubmitting(!formErrors)
        }
    }

    ////////////////////////////////////////////
    // GraphQL Calls
    ////////////////////////////////////////////
    const fetchMdmCompanies = async () => {
        let hasNextPage = true
        let endCursor = ""
        let companyList = []
        while (hasNextPage) {
            const response = await makeApolloClientCall(MDM_COMPANIES_QUERY, false, {
                after: endCursor
            })
            const companies = getValueFromEdges(response.mdmCompanies.edges)

            endCursor = response.mdmCompanies.pageInfo.endCursor
            hasNextPage = response.mdmCompanies.pageInfo.hasNextPage
            companyList = [...companyList, ...companies]

            if (!hasNextPage) setIsLoading(false)
        }
        setCompanyList(() => companyList)
    }

    const extractBrandsFromRelationships = (tree, companyId) => {
        const company = findCompany(tree, companyId)
        return extractAllBrandsFromCompany(company);
    }

    const findCompany = (tree, companyId) => {
        if (tree.id === companyId) {
            return tree;
        } else {
            for (const child in tree.children) {
                const candidate = findCompany(tree.children[child], companyId);
                if (candidate) {
                    return candidate
                }
            }
            return null;
        }
    }

    const extractAllBrandsFromCompany = function* (tree){
        if (tree?.type === "brand") {
            yield {
                value: tree.id,
                displayName: tree.name,
            }
        } else {
            for (const child in tree?.children) {
                yield* extractAllBrandsFromCompany(tree?.children[child])
            }
        }
    }

    const fetchBrandsByCompany = () => {
        if (data.form.companies.length > 0) {
            const companyIds = data.form.companies.map(company => company.value);
            makeApolloClientCall(MDM_BRANDS_QUERY, false, {
                nodeIds: companyIds
            }).then((response) => {
                let brandList = [];
                const companies = response.mdmCompanies.edges;
                companies.forEach(company => {
                    const relationships = company.node.relationships.tree
                    const brands = [...extractBrandsFromRelationships(relationships, company.node.nodeId)]
                        .map(brand => ({...brand, companyId: company.node.nodeId}))
                    brandList = brandList.concat(brands)
                })
                brandList = brandList.filter((brand, i, arr) =>
                    arr.findIndex(brandComparator => (brandComparator.value === brand.value)) === i
                )
                setBrandList(brandList)
            }).finally(() => setIsFetchingBrands(false))
        } else {
            setBrandList([])
        }
    }

    const fetchDepartments = () => {
        makeApolloClientCall(DEPARTMENTS_NEW_QUERY, false).then((response) => {
            const departments = getValueFromEdges(response.departments.edges)
            setDepartmentList(departments)

            if (isEdit) {
                const selectedDepartmentIds = data.form.departments.map(d => d.value)
                const selectedCategoryIds = data.form.categories.map(c => c.value)
                const selectedSubcategoryIds = data.form.subcategories.map(s => s.value)

                const departmentsWithNode =
                    departments.filter(department => selectedDepartmentIds.includes(department.value))

                const categoriesWithNode = getCategoriesFromDepartments(departmentsWithNode)
                setCategoryList(categoriesWithNode)
                const filteredCategories =
                    categoriesWithNode.filter(category => selectedCategoryIds.includes(category.value))

                const subcategoriesWithNode = getSubcategoriesFromCategories(filteredCategories)
                setSubcategoryList(subcategoriesWithNode)
                const selectedSubcategories =
                    subcategoriesWithNode.filter(subcategory => selectedSubcategoryIds.includes(subcategory.value))

                setData({
                    ...data,
                    form: {
                        ...data.form,
                        departments: departmentsWithNode.length > 0 ? departmentsWithNode : [DEFAULT_DEPARTMENT],
                        categories: filteredCategories.length > 0 ? filteredCategories : [DEFAULT_CATEGORIES],
                        subcategories: selectedSubcategories.length > 0 ? selectedSubcategories : [DEFAULT_SUBCATEGORIES]
                    }
                })
            }
        })
    }

    const clearForm = () => {
        setData({...data, form: {...setForm({}),}})
        setIsToEmailClear(true)
        setIsCcEmailClear(true)
        setIsBccEmailClear(true)
    }

    const getValueFromEdges = (edges) => {
        return edges?.map((edge) => edge.node)
    }

    const getCategoriesFromDepartments = (departments) => {
        return departments.map(department => getValueFromEdges(department.categories.edges)).flat()
    }

    const getSubcategoriesFromCategories = (categories) => {
        return categories.map(category => getValueFromEdges(category.subcategories.edges)).flat()
    }

    const handleDepartmentChange = (value) => {
        onChange(value, "departments")

        const allCategories = getCategoriesFromDepartments(value)
        setCategoryList(allCategories)
        const categories = data.form.categories.filter(category => allCategories.includes(category))
        onChange(categories.length > 0 ? categories : [DEFAULT_CATEGORIES], "categories")

        const allSubcategories = getSubcategoriesFromCategories(data.form.categories)
        setSubcategoryList(allSubcategories)
        const subcategories = data.form.subcategories.filter(subcategory => allSubcategories.includes(subcategory))
        onChange(subcategories.length > 0 ? subcategories : [DEFAULT_SUBCATEGORIES], "subcategories")
    }

    const handleCategoryChange = (value) => {
        onChange(value, "categories")

        const allSubcategories = getSubcategoriesFromCategories(value)
        setSubcategoryList(allSubcategories)
        const subcategories = data.form.subcategories.filter(subcategory => allSubcategories.includes(subcategory))
        onChange(subcategories.length > 0 ? subcategories : [DEFAULT_SUBCATEGORIES], "subcategories")
    }

    const handleCompanyChange = (value) => {
        onChange(value, "companies")

        const companyIds = value.map(company => company.value)
        const selectedBrandIds = data.form.brands.map(brand => brand.value)
        const brandsUnderCompanies = brandList.filter(brand =>
            companyIds.includes(brand.companyId) && selectedBrandIds.includes(brand.value))
        onChange(brandsUnderCompanies, "brands")
    }

    const handleDefaultValue = (stateKey, value) => {
        if (data.form[stateKey].findIndex(d => d.value === ALL_OPTIONS) > 0 || data.form[stateKey].length <= 0) {
            onChange([value], stateKey)
            return
        }

        if (data.form[stateKey].length > 1 && data.form[stateKey].find(d => d.value === ALL_OPTIONS)) {
            const selection = data.form[stateKey].filter(d => d.value !== ALL_OPTIONS)
            onChange(selection, stateKey)
        }
    }

    useEffect(() => fetchBrandsByCompany(), [data.form.companies])

    useEffect(() => {
        fetchDepartments()
    }, [])

    useEffect(() => {
        fetchMdmCompanies().then()
    }, [])

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            const valueMap = ({value}) => value.toString();
            const brandIds = data.form.brands.map(valueMap)
            const departmentIds = data.form.departments.map(valueMap)
            const categoryIds = data.form.categories.map(valueMap)
            const subcategoryIds = data.form.subcategories.map(valueMap)
            const variables = {
                data: {
                    id,
                    filePrefixName: data.form.filePrefixName,
                    companyIds: data.form.companies.map(valueMap),
                    brandIds: brandIds,
                    departmentIds: departmentIds.find(id => id === ALL_OPTIONS) ? [] : departmentIds,
                    categoryIds: categoryIds.find(id => id === ALL_OPTIONS) ? [] : categoryIds,
                    subcategoryIds: subcategoryIds.find(id => id === ALL_OPTIONS) ? [] : subcategoryIds,
                    toEmailRecipients: data.form.toEmailRecipients,
                    ccEmailRecipients: data.form.ccEmailRecipients,
                    bccEmailRecipients: data.form.bccEmailRecipients,
                    dataGroup: data.form.dataGroup,
                    dataGroupId: data.form.dataGroupId,
                    productUniverses: data.form.productUniverses,
                    aggregationPeriods: data.form.aggregationPeriods.map(valueMap)
                }
            }

            makeApolloClientCall(UPSERT_MUTATION, false, variables).then(() => {
                toast.success("Subscription Saved");
                if (!isEdit) clearForm()
            }).catch((error) => {
                toast.error(`${error.networkError.result.errors[0].message}`);
            }).finally(() => {
                setIsSubmitting(false)
                setId(initialState.id)
            })
        }
    }, [errors, isSubmitting])

    if (isLoading) {
        return (<CircularProgress className={"spinning-loader"} data-testid={"main-loader"}/>)
    }

    return (
        <div className="create-container">
            <ToastContainer/>
            <div className="create header" style={{display: "flex"}}>
                <p style={{fontSize: "30px", fontWeight: "bold"}}>Subscription &nbsp;</p>
                <p style={{marginTop: "1%"}}> {id} </p>
            </div>
            <Backdrop sx={{ color: "#FFFFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
                <CircularProgress className={"spinning-loader"}/>
            </Backdrop>
            <div className="create-form">
                <form>
                    <div className="create-cns-subscription">
                        <TextField
                            label="File Prefix Name"
                            error={!!errors.filePrefixName}
                            helperText={errors.filePrefixName}
                            value={data.form.filePrefixName}
                            onChange={(event) => onChange(event.target.value, "filePrefixName")}
                        />
                        <div className="email-recipient">
                            <EmailTextBox
                                label="To Email Recipients"
                                value={data.form.toEmailRecipients}
                                error={errors.toEmailRecipients}
                                setError={(value) => setErrors({
                                        ...errors,
                                        toEmailRecipients: value
                                    }
                                )}
                                clearSelectedValues={isToEmailClear}
                                onChange={(value) => onChange(value, "toEmailRecipients")}
                            />
                        </div>
                        <ComboBox
                            label="Company"
                            error={errors.companies}
                            value={data.form.companies}
                            isMultiple={true}
                            disableCloseOnSelect={false}
                            data={companyList}
                            getOptionKeyImplementation={(option) => option.value}
                            onChange={(event, value) => {
                                handleCompanyChange(value)
                                setIsFetchingBrands(true)
                            }}
                        />
                        <div>
                            <EmailTextBox
                                label="CC Email Recipients"
                                value={data.form.ccEmailRecipients}
                                error={errors.ccEmailRecipients}
                                setError={(value) => setErrors({
                                        ...errors,
                                        ccEmailRecipients: value
                                    }
                                )}
                                clearSelectedValues={isCcEmailClear}
                                onChange={(value) => onChange(value, "ccEmailRecipients")}
                            />
                        </div>
                        <ComboBox
                            label="Brands"
                            error={errors.brands}
                            value={data.form.brands}
                            data={isFetchingBrands ? [] : brandList}
                            isLoading={isFetchingBrands}
                            onChange={(event, value) => onChange(value, "brands")}
                        />
                        <div>
                            <EmailTextBox
                                label="BCC Email Recipients"
                                value={data.form.bccEmailRecipients}
                                error={errors.bccEmailRecipients}
                                setError={(value) => setErrors({
                                        ...errors,
                                        bccEmailRecipients: value
                                    }
                                )}
                                clearSelectedValues={isBccEmailClear}
                                onChange={(value) => onChange(value, "bccEmailRecipients")}
                            />
                        </div>
                        <ComboBox
                            label="Department"
                            error={errors.departments}
                            value={data.form.departments}
                            skipSort={true}
                            data={departmentList}
                            freeSolo={true}
                            onChange={(event, value) => {
                                handleDepartmentChange(value)
                                handleDefaultValue("departments", DEFAULT_DEPARTMENT)
                            }}
                        />
                        <TextField
                            label="Data Group"
                            value={dataGroup}
                            disabled={true}
                        />
                        <ComboBox
                            label="Category"
                            error={errors.categories}
                            value={data.form.categories}
                            skipSort={true}
                            data={categoryList}
                            freeSolo={true}
                            onChange={(event, value) => {
                                handleCategoryChange(value)
                                handleDefaultValue("categories", DEFAULT_CATEGORIES)
                            }}
                        />
                        <TextField
                            label="Data Group ID"
                            value={dataGroupId}
                            disabled={true}
                        />
                        <ComboBox
                            label="Subcategory"
                            value={data.form.subcategories}
                            skipSort={true}
                            data={subcategoryList}
                            freeSolo={true}
                            onChange={(event, value) => {
                                onChange(value, "subcategories")
                                handleDefaultValue("subcategories", DEFAULT_SUBCATEGORIES)
                            }}
                        />
                        <TextField
                            label="Product Universe"
                            value={productUniverses}
                            disabled={true}
                        />
                        <div></div>
                        <ComboBox
                            label="Aggregation Periods"
                            data={aggregationPeriods}
                            skipSort={true}
                            value={aggregationPeriods}
                            disabled={true}
                        />
                    </div>
                </form>
            </div>
            <div className="buttons-container">
                <div style={{display: "flex", gap: "10px"}}>
                    <div>
                        <Button type="button"
                                variant="outlined"
                                disabled={isSubmitting}
                                onClick={(event) => handleSubmit(event, setOpenOverwriteDialog)}>
                            {isEdit ? "Save Changes" : "Create"}
                        </Button>
                    </div>
                    <div>
                        {
                            isEdit ?
                                <Button type="button"
                                        variant="outlined"
                                      disabled={isSubmitting}
                                      onClick={async (event) => {
                                          setId(null)
                                          handleSubmit(event, setOpenDuplicateDialog)
                                      }}>
                                    Duplicate
                                </Button> : null
                        }
                    </div>
                </div>
            </div>
            <div>
                <ConfirmationDialog
                    isOpen={openOverwriteDialog}
                    message={"Are you sure you want to overwrite " + data.form.filePrefixName + "?"}
                    handleClose={(value) => handleDialogClose(value, setOpenOverwriteDialog)}
                    handleConfirm={(value) => handleConfirm(value, setOpenOverwriteDialog)}
                    title={"Overwrite Changes"}
                />
                <ConfirmationDialog
                    isOpen={openDuplicateDialog}
                    message={"Do you want to create a new subscription with file prefix name: " + data.form.filePrefixName + "?"}
                    handleClose={(value) => handleDialogClose(value, setOpenDuplicateDialog)}
                    handleConfirm={(value) => handleConfirm(value, setOpenDuplicateDialog)}
                    title={"Duplicate subscription"}
                />
            </div>


        </div>
    )
}


