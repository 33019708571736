import React from 'react'
import {Autocomplete, Chip, TextField} from "@mui/material";

export const ComboBox = ({label, data, onChange, value, error, onInputChange, isLoading, inputValue, skipSort, isMultiple, disableCloseOnSelect, disabled, freeSolo, testId, getOptionKeyImplementation = null, filterOptions = undefined}) => {
    return (
        <div className="combobox-div">
            <Autocomplete
                filterOptions={filterOptions}
                multiple={isMultiple === undefined ? true : !!isMultiple }
                disableCloseOnSelect={disableCloseOnSelect === undefined ? true : !!disableCloseOnSelect}
                disabled={disabled ? disabled : false}
                id={label}
                data-testid={testId}
                key={value === null ? label : undefined}
                clearOnBlur={true}
                onInputChange={onInputChange}
                value={value}
                inputValue={inputValue}
                loading={isLoading}
                loadingText={"Searching..."}
                isOptionEqualToValue={
                    (option, selectedValue) => {
                        return option.value === selectedValue.value
                    }
                }
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <Chip
                            color={option.displayName ? "default" : "error"}
                            label={option.displayName ?? option.value }
                            {...getTagProps({ index })}
                        />
                    ))
                }
                options={ skipSort ? data : data.sort((a,b) => a.displayName.localeCompare(b.displayName))}
                getOptionLabel={(option) =>  option.description ?? option.displayName}
                getOptionKey={getOptionKeyImplementation}
                onChange={onChange}
                className="combobox-autocomplete"
                renderInput={(params) => (
                    <TextField
                        error={!!error}
                        helperText={error}
                        {...params} variant="outlined"
                               label={label}/>
                )}
                freeSolo={freeSolo}
                forcePopupIcon={true}
            />
        </div>
    );
}



