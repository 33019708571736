import {DEFAULT_PATH} from "./components/auth/accessTokenValidator.js";
import {Manufacturers} from "./components/pages/Manufacturers.jsx";
import {Users} from "./components/pages/Users.jsx";
import Permissions from "./components/pages/Permissions.jsx";
import {Home} from "./components/pages/Home.jsx";
import GeoFamily from "./components/pages/metadata/GeoFamily.jsx";
import {CompanyContracts} from "./components/pages/company/CompanyContracts.jsx";
import {Company} from "./components/pages/company/Company.jsx";
import {Approvals} from "./components/pages/Approvals.jsx";
import {Powertabs} from "./components/pages/powertabs/Powertabs.jsx";
import {CreateSubscription as CreatePowertabsSubscription} from "./components/pages/powertabs/CreateSubscription.jsx";
import {Explorer} from "./components/pages/explorer/Explorer.js";
import {
    EditSubscription as PowertabsEditSubscription
} from "./components/pages/powertabs/editSubscription/EditSubscription.jsx";
import {CreateSubscription as CreateScorecardsSubscription} from "./components/pages/scorecards/CreateSubscription.jsx";
import {Subscriptions} from "./components/pages/scorecards/Subscriptions.jsx";
import {CreateContract} from "./components/pages/scorecards/CreateContract.jsx";
import {PowertabsInstances} from "./components/pages/powertabs/PowertabsInstances.jsx";
import {UpdateMarkets} from "./components/pages/scorecards/UpdateMarkets.jsx";
import {Markets} from "./components/pages/scorecards/Markets.jsx";
import {AvailableScorecards} from "./components/pages/noshe/AvailableScorecards.jsx";
import {LegacyScorecards} from "./components/pages/period-release/LegacyScorecards.jsx";
import {RetailIntelligence} from "./components/pages/period-release/RetailIntelligence.jsx";
import {EditSubscription} from "./components/pages/cns/subscription/EditSubscription.jsx";
import {CreateSubscription} from "./components/pages/cns/subscription/CreateSubscription.jsx";
import {IframeTester} from "./components/pages/noshe/IframeTester.jsx";
import {AccessAdmin} from "./components/pages/noshe/AccessAdmin/AccessAdmin.jsx";
import {SubscriptionList} from "./components/pages/cns/subscriptionList.jsx";
import {AdminOptions} from "./components/pages/cns/AdminOptions.jsx";
import {VMInstances} from "./components/pages/cns/VMInstances.jsx";
import {MidMarket} from "./components/pages/mid-market/MidMarket.jsx";
import {DataSubscription} from "./components/pages/data-subscription/DataSubscription.jsx";
import {SubscriptionsList} from "./components/pages/data-subscription/subscriptions-list/SubscriptionsList.jsx";
import {KeyAccountsApproval} from "./components/pages/key-accounts/KeyAccountsApproval.jsx";


export const ROUTES = [
    {
        path: DEFAULT_PATH,
        component: Home,
    },
    {
        path: "/approvals",
        component: Approvals,
    },
    {
        path: "/company",
        component: Company,
    },
    {
        path: "/companyContracts",
        component: CompanyContracts,
    },
    {
        path: "/explorer",
        component: Explorer,
    },
    {
        path: "/geoFamily",
        component: GeoFamily,
    },
    {
        path: "/manufacturers",
        component: Manufacturers,
    },
    {
        path: "/permissions",
        component: Permissions,
    },
    {
        path: "/powertabs",
        component: Powertabs,
    },
    {
        path: "/powertabs/create-subscription",
        component: CreatePowertabsSubscription,
    },
    {
        path: "/powertabs/subscriptions/:id",
        component: PowertabsEditSubscription,
    },
    {
        path: "/powertabs/instances",
        component: PowertabsInstances,
    },
    {
        path: "/cs/subscriptions",
        component: SubscriptionList,
    },
    {
        path: "/cs/admin",
        component: AdminOptions,
    },
    {
        path: "/cs/vm-instances",
        component: VMInstances,
    },
    {
        path: "/cs/create-subscription",
        component: CreateSubscription,
    },
    {
        path: "/cs/edit-subscription/:id",
        component: EditSubscription,
    },
    {
        path: "/scorecards/create-subscription",
        component: CreateScorecardsSubscription,
    },
    {
        path: "/scorecards/create-contract",
        component: CreateContract,
    },
    {
        path: "/scorecards/markets",
        component: Markets,
    },
    {
        path: "/scorecards/subscriptions",
        component: Subscriptions,
    },
    {
        path: "/scorecards/update-markets",
        component: UpdateMarkets,
    },
    {
        path: "/noshe/access-admin",
        component: AccessAdmin,
    },
    {
        path: "/noshe/available-scorecards",
        component: AvailableScorecards,
    },
    {
        path: "/noshe/iframe-tester",
        component: IframeTester,
    },
    {
        path: "/users",
        component: Users,
    },
    {
        path: "/period-release/retail-intelligence",
        component: RetailIntelligence,
    },
    {
        path: "/period-release/legacy-scorecards",
        component: LegacyScorecards,
    },
    {
        path: "/mid-market-report",
        component: MidMarket,
    },
    {
        path: "/data-subscription/create",
        component: DataSubscription,
    },
    {
        path: "/data-subscription/subscriptions-list",
        component: SubscriptionsList
    },
    {
        path: "/key-accounts-approval",
        component: KeyAccountsApproval
    }
];
