import '../../../../scss/PowerTabs.scss';
import * as React from "react";
import {useEffect, useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import {SubscriptionForm} from "../SubscriptionForm.jsx";
import {useParams} from "react-router-dom";
import {makeApolloClientCall} from "../../../../api/makeApolloApiCall.js";
import {CircularProgress} from "@mui/material";
import {SUBSCRIPTIONS_QUERY_EXPANDED} from "../queries.js";
import {DEFAULT_AGGREGATION_PERIODS} from "../constants.js";

export const EditSubscription = () => {
    const [data, setData] = useState({})
    const {id} = useParams()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        makeApolloClientCall(SUBSCRIPTIONS_QUERY_EXPANDED, false, {id})
            .then((response) => {
                setData(response.data[0])
                setIsLoading(false)
            })
    }, [])

    if (isLoading) {
        return (<CircularProgress className={"spinning-loader"} data-testid={"main-loader"}/>)
    }

    return (
        <div>
            <SubscriptionForm initialState={({
                    id,
                    filePrefixName: data.filePrefixName,
                    companies: data.companies,
                    toEmailRecipients: data.toEmailRecipients,
                    ccEmailRecipients: data.ccEmailRecipients,
                    bccEmailRecipients: data.bccEmailRecipients,
                    dataGroup: data.dataGroup,
                    dataGroupId: data.dataGroupId,
                    productUniverses: data.productUniverses,
                    aggregationPeriods: data.aggregationPeriods.map(
                        input => DEFAULT_AGGREGATION_PERIODS.find(item => item.value === input)
                    ),
                    brands: data.brands,
                    departments: data.departments,
                    categories: data.categories,
                    subcategories: data.subcategories
                }
            )}/>
        </div>
    )
}


